import "./src/styles/global.css";

require(`./src/styles/root.${process.env.GATSBY_APP_ZONE || "eg"}.css`);

export const onClientEntry = () => {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css";
  document.head.appendChild(link);
};
